/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { SeoQuery } from "@/../gatsby-graphql"
import { PreviewContext } from "@context/previewContext"
import { Image } from "../../utils/image"
import logoSrc from "../../images/Citrine-Logo.png"

interface Props {
  title: string
  description?: string
  image?: Image
  lang?: string
  slug?: string
  meta?: any[]
}

function SEO({
  description = ``,
  lang = `en`,
  meta = [],
  title,
  image,
  slug = ``,
}: Props) {
  const { site } = useStaticQuery<SeoQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata?.siteUrl || ``

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaImage = image?.src?.publicURL || logoSrc
  const metaUrl = `${siteUrl}${slug}` || siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

function SEOWrapper(props: Props) {
  const { previewMode } = useContext(PreviewContext)
  if (previewMode) {
    return null
  }
  return <SEO {...props} />
}

export default SEOWrapper
