import * as React from "react"
import { PageRendererProps } from "gatsby"
import classNames from "classnames"
import Header from "../Header"
import Footer from "../Footer"
import { pageLayout, main } from "./layout.module.scss"

interface Props extends PageRendererProps {
  children: React.ReactNodeArray | React.ReactNode
  mainClassName?: string
}

function Layout({ location, children, mainClassName }: Props) {
  const rootPath = `/`
  const isRootPath = location.pathname === rootPath
  return (
    <div className={classNames(pageLayout)} data-is-root-path={isRootPath}>
      <Header />
      <div className={classNames(`h-full flex flex-col`, mainClassName)}>
        <main
          className={classNames(
            main,
            `no-padding bg-white w-screen max-w-screen overflow-hidden`
          )}
        >
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
