import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { lineBreak, linkItem } from "./footer.module.scss"
import internalLinks from "@data/internalLinks.json"

function Links() {
  return (
    <div
      className={classnames(
        `flex flex-col justify-between items-center text-citrine-gray-medium py-4 px-0 h-full lg:py-8`,
        lineBreak
      )}
    >
      <Link to={internalLinks.about.footer.link} className={linkItem}>
        {internalLinks.about.footer.text}
      </Link>
      <Link to={internalLinks.charities.link} className={linkItem}>
        {internalLinks.charities.text}
      </Link>
      <Link to={internalLinks.grants.link} className={linkItem}>
        {internalLinks.grants.text}
      </Link>
      <Link to={internalLinks.gifts.link} className={linkItem}>
        {internalLinks.gifts.text}
      </Link>
      <Link to={internalLinks.contactUs.link} className={linkItem}>
        {internalLinks.contactUs.text}
      </Link>
    </div>
  )
}

export default Links
