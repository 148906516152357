import React, { ReactElement, useContext } from "react"
import classnames from "classnames"
import { PreviewContext } from "@context/previewContext"
import LogoImage from "@assets/Citrine-Logo.svg"
import { logoBoxShadow } from "./nav.module.scss"

function Logo(): ReactElement {
  const { previewMode } = useContext(PreviewContext)
  return (
    <div
      className={classnames(
        logoBoxShadow,
        `w-24 h-20 p-2.67 bg-white flex justify-center items-center`,
        `sm:w-32 sm:h-26.67 sm:p-4`,
        `md:w-40 md:h-33.33`,
        `2xl:w-48 2xl:h-40`
      )}
    >
      {previewMode ? (
        <img src={(LogoImage as unknown) as string} alt="" />
      ) : (
        <LogoImage />
      )}
    </div>
  )
}

export default Logo
