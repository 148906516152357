import classNames from "classnames"
import React, { ReactElement } from "react"
import Nav from "../Nav"
import { headerBoxShadow } from "./header.module.scss"

function Header(): ReactElement {
  return (
    <header
      className={classNames(
        `sticky z-50 top-0 bg-white w-full`,
        headerBoxShadow
      )}
    >
      <Nav />
    </header>
  )
}

export default Header
