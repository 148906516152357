import React, { ReactElement } from "react"
import classnames from "classnames"
import { MenuIcon, XIcon } from "@heroicons/react/solid"

interface Props {
  onClick?: () => void
  isOpen: boolean
}

function MobileMenuIcon({ onClick, isOpen }: Props): ReactElement {
  return (
    <div
      onClick={onClick}
      role="link"
      tabIndex={0}
      aria-label="Mobile Menu Toggle"
      className={classnames(
        `h-full w-16 p-4 flex justify-center items-center absolute right-0 top-0 text-citrine-black cursor-pointer hover:text-citrine-primary-dark`,
        `lg:hidden`
      )}
      onKeyPress={({ key }) => {
        if (key === `Enter` || key === ` `) {
          onClick()
        }
      }}
    >
      {isOpen ? (
        <XIcon className="h-full w-full" />
      ) : (
        <MenuIcon className="h-full w-full" />
      )}
    </div>
  )
}

export default MobileMenuIcon
