import { createContext } from "react"
import { Image } from "../utils/image"

export interface IPreviewContext {
  previewMode?: boolean
  _getAsset?: (image?: string) => { toString: () => string }
  getAsset?: (image?: string | Image["src"]) => string
}

export const PreviewContext = createContext<IPreviewContext>({
  previewMode: false,
  getAsset: () => ``,
})
