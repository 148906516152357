import React, { ReactElement, useContext } from "react"
import classnames from "classnames"
import { PreviewContext } from "@context/previewContext"
import GrayLogo from "@assets/Citrine-Logo-Gray.svg"
import { lineBreak } from "./footer.module.scss"

function Address(): ReactElement {
  const { previewMode } = useContext(PreviewContext)
  return (
    <div
      className={classnames(
        `flex flex-col justify-center items-center text-citrine-gray-medium pb-4 md:h-full lg:pb-0`,
        lineBreak
      )}
    >
      {previewMode ? (
        <img
          src={(GrayLogo as unknown) as string}
          alt=""
          className={classnames(
            `w-1/2 max-w-logo mb-4 pt-8 md:pt-0 lg:mb-8 lg:w-48 xl:w-55`
          )}
        />
      ) : (
        <GrayLogo
          className={classnames(
            `w-1/2 max-w-logo mb-4 pt-8 md:pt-0 lg:mb-8 lg:w-48 xl:w-55`
          )}
        />
      )}
      <div>
        <div className={classnames(`mb-2 text-center`)}>
          P.O. Box 256 | Bala, ON | P0C 1A0
        </div>
        <div
          className={classnames(`flex flex-col justify-center items-center`)}
        >
          <div className="text-center">CHARITABLE NUMBER</div>
          <div className="text-center">849854831 RR0001</div>
        </div>
      </div>
    </div>
  )
}

export default Address
