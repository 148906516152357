import React, { ReactElement } from "react"
import classnames from "classnames"

function Quote(): ReactElement {
  return (
    <div
      className={classnames(
        `flex flex-col justify-center items-center text-citrine-gray-medium pt-4 pb-8`,
        `md:pb-0 md:pt-0 md:h-full`
      )}
    >
      <div>Bridging the gap</div>
      <div>to help individuals and</div>
      <div>communities thrive</div>
    </div>
  )
}

export default Quote
