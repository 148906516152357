import React, { ReactElement } from "react"
import classnames from "classnames"

function CopyRight(): ReactElement {
  return (
    <div
      className={classnames(`px-3 py-4 border-t border-citrine-primary-dark`)}
    >
      <div className="container">
        ©{new Date().getFullYear()} The Citrine Foundation
      </div>
    </div>
  )
}

export default CopyRight
