import React, { ReactElement } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Link } from "gatsby"
import classnames from "classnames"
import internalLinks from "@data/internalLinks.json"
import {
  navItemActiveGradient,
  mobileNavLinksBoxShadow,
  navLinkItemHoverGradient,
} from "./nav.module.scss"
import Logo from "./Logo"
import MobileMenuIcon from "./MobileMenuIcon"

const NavItems = [
  {
    to: internalLinks.about.header.link,
    title: internalLinks.about.header.text,
  },
  {
    to: internalLinks.charities.link,
    title: internalLinks.charities.text,
  },
  {
    to: internalLinks.grants.link,
    title: internalLinks.grants.text,
  },
  {
    to: internalLinks.gifts.link,
    title: internalLinks.gifts.text,
  },
  {
    to: internalLinks.contactUs.link,
    title: internalLinks.contactUs.text,
  },
]

function Nav(): ReactElement {
  return (
    <nav
      className={classnames(
        `container nav h-mobile-nav flex justify-between lg:h-desktop-nav lg:justify-start`
      )}
    >
      <Link
        to={internalLinks.home.link}
        key={internalLinks.home.link}
        className={classnames(`z-10`)}
        aria-label={`Link to ${internalLinks.home.text} Page`}
      >
        <Logo />
      </Link>
      <Menu
        as="div"
        className={classnames(
          `absolute top-0 right-0 left-0 h-mobile-nav text-citrine-gray-medium font-bold text-sm focus:outline-none`,
          `lg:hidden`
        )}
      >
        {({ open }) => (
          <>
            <Menu.Button>
              <MobileMenuIcon isOpen={open} />
            </Menu.Button>
            <Transition
              show={open}
              enter="transform transition duration-200 left-0"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition duration-200 left-0"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              as="div"
              className="absolute w-full top-0"
            >
              <Menu.Items as="div" className="focus:outline-none" static>
                <ul
                  className={classnames(
                    mobileNavLinksBoxShadow,
                    `absolute top-mobile-nav-height bg-white z-0 pt-8 h-mobile-nav-container flex flex-col items-center w-full`,
                    `sm:text-xl md:pt-12`
                  )}
                >
                  {[
                    {
                      to: internalLinks.home.link,
                      title: internalLinks.home.text,
                    },
                  ]
                    .concat(NavItems)
                    .map(({ title, to }) => (
                      <Menu.Item key={to + title}>
                        {({ active }) => (
                          <Link
                            to={to}
                            key={to}
                            className={classnames(
                              navLinkItemHoverGradient,
                              `text-center flex justify-center items-center text-current no-underline font-bold w-full h-8 whitespace-nowrap hover:text-citrine-black focus:text-citrine-black py-6`,
                              {
                                [navItemActiveGradient]: active,
                                "text-citrine-black": active,
                              },
                              `sm:py-7`
                            )}
                            activeClassName={navItemActiveGradient}
                          >
                            {title}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                </ul>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <div
        className={classnames(
          `hidden font-bold text-sm h-full w-full ml-6`,
          `lg:pt-0 lg:inline-flex`,
          `xl:ml-6 xl:text-base`,
          `2xl:ml-7`
        )}
      >
        {NavItems.map(({ title, to }) => (
          <Link
            to={to}
            key={to}
            className={classnames(
              navLinkItemHoverGradient,
              { "flex-shrink-0": title.split(` `).length <= 2 },
              `text-center inline-flex justify-center items-center text-current no-underline font-bold h-8 whitespace-nowrap hover:text-citrine-black focus:bg-citrine-black`,
              `lg:whitespace-normal lg:px-2 lg:h-full lg:max-w-1/${NavItems.length} w-fit-content align-middle`,
              `xl:whitespace-nowrap xl:p-3 xl:max-w-none`,
              `2xl:p-4`
            )}
            activeClassName={navItemActiveGradient}
            aria-label={`Link to ${title} Page`}
          >
            {title}
          </Link>
        ))}
      </div>
    </nav>
  )
}

export default Nav
