import React from "react"
import classnames from "classnames"
import CopyRight from "./CopyRight"
import Quote from "./Quote"
import Links from "./Links"
import Address from "./Address"
import { footerGradient } from "./footer.module.scss"

function Footer() {
  return (
    <footer className={classnames(footerGradient, `w-full no-padding`)}>
      <div
        className={classnames(
          `container grid overflow-hidden text-sm font-bold sm:text-base md:py-8 lg:grid-cols-3 lg:auto-rows-fr lg:py-11 xl:py-15 xl:text-lg`
        )}
      >
        <Address />
        <Links />
        <Quote />
      </div>
      <CopyRight />
    </footer>
  )
}

export default Footer
